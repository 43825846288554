import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { injectTrpcClient } from '@core/services/trpc';
import { getApiResponse } from '@core/services/trpc/trpc.utils';
import { filter, map } from 'rxjs';

export const EventGuard: CanActivateFn = (route) => {
	const router = inject(Router);
	const trpc = injectTrpcClient();

	const seoUrl = route.paramMap.get('seoUrl');
	if (!seoUrl) {
		return router.createUrlTree(['/dashboard']);
	}

	return getApiResponse(trpc.event.getEventBySeoUrl.query(seoUrl)).pipe(
		filter((res) => res.status !== 'loading'),
		map((res) => {
			if (res.status === 'error') {
				console.error(res.error);
				return router.createUrlTree(['/dashboard']);
			}

			return true;
		})
	);
};
