import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { filter, map } from 'rxjs';
import { AuthService } from '@core/services/auth';

export const LoggedOutGuard: CanActivateFn = () => {
	const router = inject(Router);
	const auth = inject(AuthService);

	return auth.checkLoggedIn.pipe(
		filter((res) => res !== null),
		map((isLoggedIn) => {
			if (!isLoggedIn) {
				return true;
			}

			return router.createUrlTree(['/dashboard']);
		})
	);
};
